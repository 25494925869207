@import "../../assets/base/variables";

.calc-results {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0 0 10px;
  dt, dd {
    float: left;
    padding: 0;
    margin: 0
  }
  dt {
    width: 120px;
    font-weight: bold;
  }
  dd {
    width: calc(100% - 120px);
    color: $gray;
  }
}