.package-section {
  width: 100%;
  padding: 40px 0 0;
  display: flex;
  flex-direction: column;
  .custom-heading {
    flex-shrink: 0;
    flex-basis: auto;
  }
  .checked-table-wrap {
    display: flex;
    flex-grow: 1;
  }
  h3 {
    margin: 0 0 20px;
  }
}