@import "../../assets/base/variables";

.info-box {
  h3 {
    margin: 0 0 30px;
  }
  p {
    color: $gray;
    margin-bottom: 40px;
  }
  img,
  iframe {
    margin-bottom: 40px;
  }
}