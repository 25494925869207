.checked-table-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  .table-container {
    overflow: auto;
    flex-grow: 1;
    width: 100%;
  }
}

.checked-table {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
  margin-bottom: 40px;
  tr th:first-child,
  tr td:first-child {
    text-align: left;
  }
  .MuiCheckbox-root {
    margin-left: -15px;
  }
}