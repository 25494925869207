@import "../../assets/base/variables";

.custom-card {
  width: 100%;
  background: $light-blue;
  margin: 0 auto 20px;
  box-shadow: 6px 6px $blue;
  cursor: pointer;
  .custom-card-holder {
    padding: 20px;
  }
  .arrow-next{
    transform: rotate(180deg);
  }
  h3 {
    margin-bottom: 60px;
  }
}