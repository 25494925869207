@import "../../assets/base/variables";

.table-row {
  border-bottom: 1px solid black;
  color: $gray;
  &.checked {
    color: $black;
  }
  .table-col-flex {
    display: flex;
    align-items: center;
  }
}