@import "../../assets/base/variables";

.num-input {
  margin: 10px 0 10px auto;
  min-width: 28px;
  height: 28px;
  border: 1px solid $black;
  color: $black;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}