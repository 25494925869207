@import "../../assets/base/variables";

.info-btn {
  cursor: pointer;
  margin-left: 10px;
  vertical-align: middle;
  color: $gray;
  @media print {
    display: none !important;
  }
}