html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p {
    margin: 0 0 20px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .MuiContainer-root {
    padding: 0 30px;
  }
  .app-header {
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    padding: 10px 30px;
    @media print {
      display: none;
    }
  }
}

.main-container {
  flex-grow: 1;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
}