@import "../../assets/base/variables";

.checked-table-results {
  flex-shrink: 0;
  flex-basis: auto;
  background: $pastel-blue;
  border-top: 1px solid $black;
  padding: 20px 0 60px;
  .btn-wrap {
    @media print {
      display: none;
    }
  }
  .pdf-btn,
  .order-btn {
    text-decoration: none;
    color: $black;
    font-weight: bold;
    &.disabled {
      pointer-events: none;
      color: $gray;
    }
  }
}